import React, { useEffect, useState } from "react";
import "./App.css";

import ReactGA from "react-ga4";

const getMinuteSuffix = (minute: number) => {
  switch (true) {
    case minute === 0:
      return "a";
    case minute === 1:
      return "u";
    case minute === 2:
    case minute === 3:
    case minute === 4:
      return "e";
    case minute > 4 && minute <= 20:
      return "a";
    case minute === 21:
    case minute === 31:
    case minute === 41:
    case minute === 51:
      return "u";
    case minute > 21 && minute < 25:
    case minute > 31 && minute < 35:
    case minute > 41 && minute < 45:
    case minute > 51 && minute < 55:
      return "e";
    case minute > 24 && minute < 31:
    case minute > 34 && minute < 41:
    case minute > 44 && minute < 51:
    case minute > 54:
      return "a";
  }
};

const getSekundaSuffix = (sekunda: number) => {
  switch (true) {
    case sekunda === 0:
      return "i";
    case sekunda === 1:
      return "u";
    case sekunda === 2:
    case sekunda === 3:
    case sekunda === 4:
      return "e";
    case sekunda > 4 && sekunda <= 20:
      return "i";
    case sekunda === 21:
    case sekunda === 31:
    case sekunda === 41:
    case sekunda === 51:
      return "u";
    case sekunda > 21 && sekunda < 25:
    case sekunda > 31 && sekunda < 35:
    case sekunda > 41 && sekunda < 45:
    case sekunda > 51 && sekunda < 55:
      return "e";
    case sekunda > 24 && sekunda < 31:
    case sekunda > 34 && sekunda < 41:
    case sekunda > 44 && sekunda < 51:
    case sekunda > 54:
      return "i";
  }
};

const App = () => {
  const [kola] = useState([
    new Date("2/3/2025 17:00"),
    new Date("2/3/2025 17:45"),
    new Date("2/3/2025 18:30"),
    new Date("2/3/2025 19:15"),
    new Date("2/3/2025 20:00"),

    new Date("3/3/2025 17:00"),
    new Date("3/3/2025 17:45"),
    new Date("3/3/2025 18:30"),
    new Date("3/3/2025 19:15"),
    new Date("3/3/2025 20:00"),

    new Date("4/3/2025 17:00"),
    new Date("4/3/2025 17:45"),
    new Date("4/3/2025 18:30"),
    new Date("4/3/2025 19:15"),
    new Date("4/3/2025 20:00"),

    new Date("7/25/2025 21:15"),
  ]);

  const [nextKolo, setNextKolo] = useState<Date>();

  const [i, refresh] = useState(0);

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Page View",
    });
  }, []);

  useEffect(() => {
    const next = kola.find((d) => d.getTime() > new Date().getTime());

    setNextKolo(next);

    setTimeout(() => {
      refresh(i + 1);
    }, 1000);
  }, [i, refresh, setNextKolo, kola]);

  const time =
    (nextKolo || new Date("7/29/2022 21:15")).getTime() - new Date().getTime();

  const daysDistance = Math.floor(time / (1000 * 60 * 60 * 24));
  const hoursDistance = Math.floor(
    (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesDistance = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const secondsDistance = Math.floor((time % (1000 * 60)) / 1000);

  return (
    <div className="App">
      <header
        className="App-header"
        style={{ backgroundImage: "url(/ribbon.png)" }}
      >
        <p className="title">Sljedeće kolo je za:</p>
        {/* <p className="subtitle">(ili po voji glazbara)</p> */}
        {daysDistance > 0 && (
          <span className="App-link dan">
            {daysDistance} dan{daysDistance > 1 ? "a" : ""}
          </span>
        )}
        {/* {hoursDistance > 0 && ( */}
        <span className="App-link sat">
          {hoursDistance} sat{hoursDistance === 1 ? "" : "a"}
        </span>
        {/* )} */}
        {/* {minutesDistance > 0 && ( */}
        <span className="App-link minut">
          {minutesDistance} minut{getMinuteSuffix(minutesDistance)}
        </span>
        {/* )} */}
        {/* {secondsDistance > 0 && ( */}
        <span className="App-link sekund">
          {secondsDistance} sekund{getSekundaSuffix(secondsDistance)}
        </span>
        {/* )} */}
      </header>

      <a className="osansto" href="https://800.hr/">
        800
      </a>
    </div>
  );
};

export default App;
